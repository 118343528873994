.pagination{
    float: right;
}
.tdif{
    padding:2px !important;
}
.react-tiny-popover-container {
    max-width: 500px;
    background-color: #f8f9fa;
    z-index: 9999 !important;
}
.progress{
    margin-top: 5px;
    border: 1px solid #686767;
}

.help_text_red {
    font-weight: 900;
    font-size: 10px;
    color: red
} 

.project_details_project_milestone_table td:nth-child(2) {
    display: none;
}

.project_details_project_milestone_table th:nth-child(2) {
    display: none;
}

.project_details_project_milestone_table td:nth-child(3) {
    display: none;
}

.project_details_project_milestone_table th:nth-child(3) {
    display: none;
}

.linebreaksch {
    background: white;
    margin: 1px
}
.sch_report_border {
    border-right : solid 10px;
    border-left  : solid 10px;
    outline      : solid black 1px
}

.inputgroupfilter1{
    z-index: 100;
}
.inputgroupfilter2{
    z-index: 99;
}
svg:hover {
    transform: scale(1.05);
}
.webColor{
    color: #7367f0
}

.select__multi-value{
    background-color: #7367f0
}

.taskCard{
    /* border : solid 1px #7367f0 */
    border : solid 1px #6200ff 
}

.pointer{
    /* border : solid 1px #7367f0 */
    cursor : pointer 
}

.disabledpointer{
    /* border : solid 1px #7367f0 */
    cursor : not-allowed 
}

.bg-danger-light{
    border: solid #fdccd1 1px;
}
.bg-warning-light{
    border: solid #f3d2a5 1px;
}
.bg-primary-light{
    border: solid #ddcbfb 1px;
}
.bg-info-light{
    border: solid #b1dce9 1px;
}

.bg-danger{
    border: solid #c50014 1px;
}
.bg-warning{
    border: solid #895002 1px;
}
.bg-primary{
    border: solid #6200ff 1px;
}
.bg-info{
    border: solid #048695 1px;
    color : black
}
.bg-success{
    border: solid #286c00 1px;
}
.bg-dark{
    border: solid #000000 1px;
}

.border-danger{
    border: solid #f7061e 1px;
}
.border-warning{
    border: solid #fa9201 1px;
}
.border-primary{
    border: solid #6200ff 1px;
}
.border-info{
    border: solid #00bdf7 1px;
}


.taskCardHeading{
    font-size: 18px;
    border-bottom: 2px #7367f0 solid;
    border-top-left-radius : 0.428rem;
    border-top-right-radius: 0.428rem;
}

.task_data_list{
    padding: 0px;
    border : solid 0px
}

.subTaskSortable{
    padding-left: 0px;
}

.timerBtn {
    outline: #7367f0 1px solid;
    background: transparent;
    border: 1px solid #7367f0;
    padding: 0px 8px;
    border-radius: 7px;
    color: #7367f0;
    cursor: pointer;
  }

.buttonDisabled {
    color: black;
    outline: #757575 1px solid;
    border: #757575 1px solid;
    cursor: not-allowed;
}

.overlay{
    cursor: not-allowed;
}


.table thead th, .table tfoot th{
    text-transform: capitalize;
}

.table-responsive {
    overflow-y: auto;
    height: 500px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    z-index:1;
} 

.table-responsive table thead th {
    font-size: 10px;
}

.generate_extenion_box_select .css-qj08tm-indicatorContainer {
    padding : 0px !important;
}

/*  Custom Css Start */
.customTablecss {
    width:100%; 
	margin:0;
	border:none;
	border-collapse:separate;
	border-spacing:0;
	border-left:1px solid #bab8b8;
}

.customTablecss th {
    border-bottom: 1px solid rgba(31,34,37,.4)!important;
    border-right: 1px solid rgba(31,34,37,.4) !important;
    background-color: #7367f0 !important;
    color : rgb(255, 255, 255) !important;
    padding: 5px 16px !important;
    font-family: inherit !important;
    font-weight: 500;
}

.customTablecss td {
    border-bottom: 1px solid rgba(31,34,37,.2)!important;
    border-right: 1px solid rgba(31,34,37,.2) !important;
    font-family: inherit !important;
    font-weight: 600;
    background:#e8e5e5 !important;
}
/*  Custom Css End*/
table#extenalCssTable {
	width:100%; /* just to demonstrate horizontal scrolling & stickiness */
	margin:0;
	border:none;
	background-color:#f8f8f8;
	border-collapse:separate;
	border-spacing:0;
	border-left:1px solid #bab8b8;
}

table#extenalCssTable th {
	background:#e8e5e5;
	border:1px solid #bab8b8;
	color:#555;
	padding:3px;
	position:sticky;
	top:0;
  /* ensure header row sits atop everything else when scrolling down */
	z-index:1;
}

table#extenalCssTable td {
	border-right:1px solid #bab8b8;
	border-bottom:1px solid #bab8b8;
	padding:4px 5px;
}
table#extenalCssTable th:first-child {
	position:sticky;
	left:0;
	z-index:2;
}
table#extenalCssTable td:not(.NOSTICKYTD):first-child {
	position:sticky;
	left:0;
	border-right-color:#bab8b8;
    background:#e8e5e5;
}

.tox-tinymce{
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
}

.tox-anchorbar{
    border: 1px solid #d8d6de;
}

.tox-notifications-container{
    display: none;
}

.tr_danger_color{
    background:#ff8d98;
}

.tr_warning_color{
    background:#f1d583;
}

.tr_primary_color{
    background:#bad8f3;
}

.tr_info_color{
    background:#bef6ff;
}

.answerBody img {
    max-width: 100%;
}

.desc_editor img {
    max-width: 100%;
}

.boxShadow{
    box-shadow: 3px 4px 4px 4px #b39ce3;
}

.btn{
    box-shadow: 0px 1px 2px 1px #b39ce3;
}

.badge {
    box-shadow: 0px 1px 2px 1px #b39ce3;
}

.cardTopBorderColordanger{
    border-top: solid 5px #c50014;
}

.cardTopBorderColorwarning{
    border-top: solid 5px #ff9f43;
}

.cardTopBorderColorprimary{
    border-top: solid 5px #7367f0;
}

.cardTopBorderColorinfo{
    border-top: solid 5px #00cfe8;
}

.taskCardShadowColordanger{
    box-shadow: 2px 1px 10px #c50014;
}

.taskCardShadowColorwarning{
    box-shadow: 2px 1px 10px #ff9f43;
}

.taskCardShadowColorprimary{
    box-shadow: 2px 1px 10px #7367f0;
}

.taskCardShadowColorinfo{
    box-shadow: 2px 1px 10px #00cfe8;
}

.editBtnShadow{
    box-shadow:  0px 0px 2px 2px #7367f0
}

.ck.ck-content:not(.ck-comment__input *) {
    height: 164px;
    overflow-y: auto;
}

.bg-indigo-transparent-1{
    background-color: #d6c0fb !important
}
.bg-red-transparent-1{
    background-color: #ffb3ba !important
}
.bg-orange-transparent-1{
    background-color: #ffcf89 !important
}
.bg-aqua-transparent-1{
    background-color: #a0eaff !important
}

.Task_Td_Success_Background{
    background-color: #5cb85c !important;
    color : white
}

.Task_Td_Danger_Background{
    background-color: #FF0000 !important;
    color : white
}

table#MuiExtenalCssTable th {
    border-bottom: 1px solid rgba(31,34,37,.4)!important;
    border-right: 1px solid rgba(31,34,37,.4) !important;
	/* text-transform: capitalize !important;
    font-weight: 500 !important; */
    background-color: rgb(203 203 203) !important;
    color : black !important;
    padding: 5px 16px !important;
    font-family: inherit !important;
    font-weight: 500;
}
table#MuiExtenalCssTable td {
    border-bottom: 1px solid rgba(31,34,37,.2)!important;
    border-right: 1px solid rgba(31,34,37,.2) !important;
    font-family: inherit !important;
    font-weight: 500;
}
table#MuiExtenalCssTable tr {
    vertical-align: initial;
}

table#MuiNormalCssTable td {
    border-bottom: 1px solid rgba(31,34,37,.2)!important;
    font-family: inherit !important;
    font-weight: 500;
}
table#MuiNormalCssTable th {
    border-bottom: 1px solid rgb(66, 66, 66) !important;
    font-family: inherit !important;
    font-weight: 500;
    background-color: rgb(203 203 203) !important;
}

table#MuiNormalCssTable tr {
   vertical-align: initial;
}

.MuiLinearProgress-bar{
    background-color: #7367f0;
}

table#MuiExtenalCssTable thead {
	text-transform: capitalize !important;
    font-weight: 500 !important;
}


table#MuiExtenalCssTable th:first-child {
	position:sticky;
	left:0;
	z-index:2;
}
table#MuiExtenalCssTable td:first-child {
	position:sticky;
	left:0;
	border-right-color:#bab8b8;
    background:#e8e5e5;
}
.ui-loader .overlay{
    z-index : 100 !important
}
.ui-loader .loader{
    z-index : 100 !important
}

.group--conjunctions {
    display: flex;
    align-items: center;
}
  
.group--conjunctions > * {
    margin-left: 5px;
    margin-right: 5px;
}

.group--actions,
.group--actions--tr button {
  opacity: 1 !important;
}
.query-builder {
    margin : 0px !important
} 
#open_task_count_report_button{
    height : 100%;
    line-height: 90%;
}

table#MuiNormalCssTableWithBorder td {
    border-bottom: 1px solid rgba(31,34,37,.2)!important;
    border-right: 1px solid rgba(31,34,37,.2) !important;
    font-family: inherit !important;
    font-weight: 500;
}
table#MuiNormalCssTableWithBorder th {
    border-bottom: 1px solid rgb(66, 66, 66) !important;
    border-right: 1px solid rgba(31,34,37,.2) !important;
    font-family: inherit !important;
    font-weight: 500;
    background-color: #7367f0 !important;
    color: white
}

table#MuiNormalCssTableWithBorder tr {
   vertical-align: initial;
}

.dashboard_box_shadow {
    box-shadow: 5px 10px 15px 
}

.dashboard_box_shadow:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

table.saas_table_info {
    font-size: 12px;
    width: 100%;
}

table.saas_table_info td {
    font-weight: 500;
}

table.saas_table_info tr {
    border-top: 1px solid black;
    border-bottom: 1px solid black;  
}

table.saas_table_info td {
    padding: 5px;
    border-left: 1px solid black;
    border-right: 1px solid black;  
}

table.saas_table_info th {
    padding: 5px;
    border-left: 1px solid black;
    border-right: 1px solid black;  
}

.header-navbar{
    z-index: 999 !important;
}



.fu_dropzone{
    border: 2px dashed rgba(255,255,255,0.2);
    border-radius: 10px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    color: #7367f0;
}
.fu_dropzone.active{
    border-color:#fff;
}
.fu_file {
    text-decoration:none;
    color: rgba(255,255,255,0.8);
    display:block;
    background-color: #41415d;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position:relative;
    overflow: hidden;
}
.fu_file .fu_progress {
    background-color: #7367f0;
    /* position: absolute; */
    inset:0;
    width:0;
    /* padding-top: 10px; */
    text-align: center;
    height: auto;
}
.fu_file .fu_progress.done{
    top: 100px;
}
.fu_progress{
    border-radius: 10px;
}
.swal2-styled.swal2-confirm {
    margin-right: 10px; /* Adjust the margin as needed */
}




